import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { api, pages } from '../factory/apiConfig';
import { useFetchUserProfile, useFetchUsers } from '../factory/useData';
import Loading from './Loading';
import Navbar from './Navbar';
import { toast } from 'react-toastify';

const Admin = () => {
	const isAuth = useSelector((state) => state.reducer.isAuth);
	const navigate = useNavigate();

	const [user, isLoadingUser] = useFetchUserProfile(localStorage.getItem('token'), isAuth);
	// eslint-disable-next-line no-unused-vars
	const [users, isLoadingUsers, isErrorUser, errorUser, refetchUsers] = useFetchUsers(
		localStorage.getItem('token'),
		isAuth,
		user?.user_id
	);

	const [adminUsers, setAdminUsers] = useState([]);
	const [nameSearch, setNameSearch] = useState('');
	const [emailSearch, setEmailSearch] = useState('');
	const [roleSearch, setRoleSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(50);
	const [openModal, setOpenModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);

	useEffect(() => {
		if (!isAuth) {
			navigate(pages.login);
		}
		if (user?.role !== 'admin') {
			navigate(pages.sites);
		}
	}, [isAuth, navigate, user?.role]);

	useEffect(() => {
		setAdminUsers(users);
	}, [users]);

	useEffect(() => {
		if (nameSearch) {
			const filteredUsers = users.filter((user) => user.user_name.toLowerCase().includes(nameSearch.toLowerCase()));
			setAdminUsers(filteredUsers);
		} else if (emailSearch) {
			const filteredUsers = users.filter((user) => user.user_email.toLowerCase().includes(emailSearch.toLowerCase()));
			setAdminUsers(filteredUsers);
		} else if (roleSearch) {
			const filteredUsers = users.filter((user) => user.role.toLowerCase().includes(roleSearch.toLowerCase()));
			setAdminUsers(filteredUsers);
		} else {
			setAdminUsers(users);
		}
	}, [emailSearch, nameSearch, roleSearch, users]);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = adminUsers?.slice(indexOfFirstItem, indexOfLastItem);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const toggleActivation = async (user) => {
		const response = await fetch(`${api.url}/${api.user}/${user.user_id}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({ is_active: !user.is_active }),
		});

		if (response.ok) {
			refetchUsers();
		}
	};

	const handleClick = (user) => {
		setUserToDelete(user || {});
		setOpenModal(true);
	};

	const handleCancelClick = () => {
		setOpenModal(false);
	};

	const updateRole = async (user) => {
		const response = await fetch(`${api.url}/${api.user}/${user.user_id}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({ role: user.role === 'admin' ? 'user' : 'admin' }),
		});

		if (response.ok) {
			toast.success('User role changed successfully!');
			refetchUsers();
		} else {
			toast.error('Something went wrong! Please try again later.');
		}
	};

	const deleteUser = async (user) => {
		if (!userToDelete) return;
		const response = await fetch(`${api.url}/${api.adminUsers}/${user.user_id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		if (response.ok) {
			toast.success('User delete succesfully!');
			setAdminUsers((prevUsers) => prevUsers.filter((u) => u.user_id !== user.user_id));
			setOpenModal(false);
		} else {
			toast.error('Something went wrong! Please try again later.');
		}
	};

	if (isLoadingUser || isLoadingUsers) {
		return <Loading />;
	}

	return (
		<>
			<Navbar />
			<div className="container mb-1">
				<div className="navigation_wrapper">
					<Link className="hover_text" to={pages.sites}>
						Webresources
					</Link>
					<span>{'>'}</span>
					<Link className="hover_text" to={pages.admin}>
						Admin panel
					</Link>
				</div>
			</div>
			<main className="container d-flex flex-column gap-2 justify-content-center">
				<div className="d-flex gap-2 fs-6">
					<input
						className="form-control w-25 h-75"
						style={{ fontSize: '0.5rem' }}
						type="text"
						placeholder="Search by Name"
						value={nameSearch}
						onChange={(e) => setNameSearch(e.target.value)}
					/>
					<input
						className="form-control w-25 h-75"
						type="text"
						style={{ fontSize: '0.5rem' }}
						placeholder="Search by Email"
						value={emailSearch}
						onChange={(e) => setEmailSearch(e.target.value)}
					/>
					<input
						className="form-control w-25 h-75"
						type="text"
						style={{ fontSize: '0.5rem' }}
						placeholder="Search by Role"
						value={roleSearch}
						onChange={(e) => setRoleSearch(e.target.value)}
					/>
				</div>
				<span className="style_text">Total users: {adminUsers ? adminUsers.length : 0}</span>
				<div className="border_bottom"></div>
				<table className="text-center w-100">
					<thead>
						<tr>
							<th scope="col">№</th>
							<th scope="col">Name</th>
							<th scope="col">Email</th>
							<th scope="col">Websites</th>
							<th scope="col">Last sign in</th>
							<th scope="col">User IP</th>
							<th scope="col">Verified user</th>
							<th scope="col">Active</th>
							<th scope="col">Role</th>
							<th scope="col">Action</th>
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((user, index) => (
							<tr key={user.user_id}>
								<th scope="row">{index + 1}</th>
								<td>{user.user_name}</td>
								<td>{user.user_email}</td>
								<td>{user.websites}</td>
								<td>{user.last_sign_in ? new Date(user.last_sign_in).toUTCString() : '-'}</td>
								<td>{user.registration_ip ? user.registration_ip : '-'}</td>
								<td>{user.is_verified ? 'Yes' : 'No'}</td>
								<td>
									<input
										className="form-check-input mt-0"
										defaultChecked={user.is_active}
										type="checkbox"
										onClick={() => toggleActivation(user)}></input>
								</td>
								<td>
									<select
										value={user.role}
										style={{ fontSize: '0.5rem' }}
										onChange={(e) => {
											updateRole(user);
										}}
										id="inputGroupSelect01">
										<option selected>Choose...</option>
										<option value="user">User</option>
										<option value="admin">Admin</option>
									</select>
								</td>
								<td>
									<button className="admin_table_delete_btn" onClick={() => handleClick(user)}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{openModal && (
					<div className="modal_admin">
						<div className="modal_body">
							<div className="modal_body_text">
								<span>
									Are you absolutely sure to delete user <span>{userToDelete ? userToDelete.user_name : ''}</span>?
								</span>
								<span className="modal_second_text">
									This action will permanently remove user and all associated data from the database
								</span>
								<span className="modal_second_text">You won't be able to revert this!</span>
							</div>
							<div className="modal_buttons">
								<button className="modal_btn" onClick={() => handleCancelClick()}>
									Cancel
								</button>
								<button className="modal_btn modal_btn_delete" onClick={() => deleteUser(userToDelete)}>
									Delete
								</button>
							</div>
						</div>
					</div>
				)}
				{currentItems && currentItems.length > 20 && (
					<div className="d-flex align-items-center justify-content-center gap-2">
						<button
							className={`btn btn-sm p-1 ${currentPage === 1 ? 'btn-secondary' : 'btn-primary '}`}
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}>
							Prev
						</button>
						<button
							className={`btn btn-sm p-1 ${currentItems?.length < itemsPerPage ? 'btn-secondary' : 'btn-primary '}`}
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentItems?.length < itemsPerPage}>
							Next
						</button>
					</div>
				)}
			</main>
		</>
	);
};
export default Admin;
